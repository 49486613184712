$mobile: 768px;

@import "./bulma-variables.scss";

@import "../../node_modules/bulmaswatch/lumen/_variables.scss";

@import "../../node_modules/bulma/bulma.sass";

@import "../../node_modules/bulmaswatch/lumen/_overrides.scss";

@import "../../node_modules/bulma-tooltip/src/sass/index.sass";

@import "../../node_modules/@mdi/font/scss/materialdesignicons.scss";

@import "./site.scss";

$breakpoints: (
    "mobile": $mobile,
    "tablet": $tablet,
    "desktop": $desktop,
    "widescreen": $widescreen,
    "fullhd": $fullhd
);

.bulma-loader-mixin {
    @include loader;
    display: inline-block;
}

.section {
    padding-bottom: 0;
}

h1 .icon,
h2 .icon,
h3 .icon,
h4 .icon {
    margin-left: 0.5rem;
}

.home-banner-container {
    position: relative;
    display: block;
    height: max(95vh, 450px);
    overflow: hidden;
}

.home-banner-video {
    min-height: 100%;
    min-width: 100%;
    max-width: none;
    max-height: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.home-banner-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: hsla(207deg, 61%, 53%, 0.9) !important;
}

.home-banner-content-subtitle {
    @media only screen and (max-width: $mobile) {
        font-size: 1.5em;
    }

    @media only screen and (min-width: $tablet) {
        font-size: 2em;
    }

    @media only screen and (min-width: $desktop) {
        font-size: 2.5em;
    }
}

.home-banner-content-title {
    @media only screen and (max-width: $mobile) {
        font-size: 4em;
    }

    @media only screen and (min-width: $tablet) {
        font-size: 5em;
    }

    @media only screen and (min-width: $desktop) {
        font-size: 7em;
    }
}

.home-banner-content-footer {
    font-size: 1.5em;
}