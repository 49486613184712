html,
body {
    height: 100%;
}

.split-layout {
    @media only screen and (max-width: $mobile) {
        & .right {
            margin-top: 1rem;
        }
    }

    @media only screen and (min-width: $tablet) {
        display: grid;
        grid-template-columns: 1fr 1fr;

        & .left {
            margin-right: 0.5rem;
        }

        & .right {
            margin-left: 0.5rem;
        }
    }
}

.features-grid {
    @media only screen and (min-width: $tablet) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
        grid-auto-rows: 1fr;

        & .box:last-child {
            margin-bottom: 1.5rem;
        }
    }
}

.button > .icon {
    margin-right: 0.05rem !important;
    font-size: 1.25rem;
}

.home-banner-content{
    & h2{
        margin-top: 3rem;

        
    }

    & code{
        margin-top: 1rem;
        padding: 0.5rem;
        background-color: rgba(255,255,255,0.75);
    }

    & p.step{
        margin-bottom: 1rem;
    }

    & h3.is-feature{
        margin-top:1rem;
        margin-bottom: 0.5rem;
    }
}

.button.has-image{
    padding-left:0px;

    & img{
        margin: 0.5rem;
        width: 20px;
        height: 20px;
    }
}